import React from "react";
import {SVGIcon} from '@cuddle-dev/web-components';

//images
import { ReactComponent as ColumnImg } from "../../../images/column_20.svg";
import { ReactComponent as LineImg } from "../../../images/line_20.svg";
import { ReactComponent as TableImg } from "../../../images/table_20.svg";
import { ReactComponent as BarImg } from "../../../images/bar_20.svg";
import { ReactComponent as ListImg } from "../../../images/list_20.svg";
import { ReactComponent as NumberImg } from "../../../images/number_20.svg";
import { ReactComponent as WaterfallImg } from "../../../images/waterfall_20.svg";
import { ReactComponent as AchievementImg } from "../../../images/achievement_widget_20.svg";

const VIZ_LABELS = {
    "TREND": "Line",
    "BAR": "Bar",
    "COLUMN": "Column",
    "TABLE": "Table",
    "LIST": "List",
    "TARGETED_AGAINST_BAR": "Achievement",
    "TARGETED_AGAINST_TREND": "Achievement",
    "MULTI_MEASURE_TREND": "Line",
    "DRIVER_TABLE": "Table",
    "ACHIEVEMENT_RING": "Achievement",
    "NUMBER": "KPI",
    "WATERFALL": "Key Drivers",
    "BAR_CHART": "Bar",
    "COLUMN_CHART": "Bar",
    "LINE_CHART": "Line",
    "TIMESERIES": "Line",
    "PRODUCT_MAP": "Product Map",
};

const VIZ_ICONS = {
    "TARGETED_AGAINST_TREND": LineImg,
    "TREND": LineImg,
    "LINE": LineImg,
    "MULTI_MEASURE_TREND": LineImg,
    "TABLE": TableImg,
    "COLUMN": ColumnImg,
    "TARGETED_AGAINST_BAR": BarImg,
    "BAR": BarImg,
    "LIST": ListImg,
    "DRIVER_TABLE": TableImg,
    "ACHIEVEMENT_RING": AchievementImg,
    "DEFAULT": TableImg,
    "NUMBER": NumberImg,
    "SINGLEVALUE": NumberImg,
    "WATERFALL": WaterfallImg,
    "BAR_CHART": BarImg,
    "COLUMN_CHART": BarImg,
    "LINE_CHART": LineImg,
    "TIMESERIES": LineImg,
    "PRODUCT_MAP": TableImg,
};

function getLabelIcon(type, color) {
    return <SVGIcon Icon={VIZ_ICONS[type] || VIZ_ICONS["DEFAULT"]} width={20} height={20} fill={color}/>
}

export {
    VIZ_ICONS,
    VIZ_LABELS,
    getLabelIcon
};